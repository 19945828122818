<template>
  <div class="estimate-modal-wrapper" ref="estimate">
    <estimate-header @print="printEstimate" @email="sendEmail"></estimate-header>
    <estimate-info></estimate-info>
    <estimate-detail v-if="is_visible"></estimate-detail>
  </div>
</template>
<script>
import EstimateHeader from '@/components/EstimateHeader.vue';
import EstimateInfo from '@/components/EstimateInfo.vue';
import EstimateDetail from '@/components/EstimateDetail.vue';
import { mapState } from 'vuex';
import { setEstimateRecord } from '@/api/order';

export default {
  name: 'estimate-wrapper',
  components: {
    EstimateHeader,
    EstimateInfo,
    EstimateDetail,
  },
  data() {
    return {
    }
  },
  props: {
    userId: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      is_hiworks: (state) => state.isHiworks,
      estimate_list: (state) => state.estimate_list,
      receiver_info: (state) => state.receiver_info,
      total_pricing: (state) => state.total_pricing,
      virtual: (state) => state.virtual,
      is_visible: (state) => state.isVisible,
    }),
  },
  methods: {
    async printEstimate() {
      const estimate_json_obj = {
        type: 'order',
        version: 'v1',
        is_hiworks: this.is_hiworks,
        receiver_info: this.receiver_info,
        total_pricing: {
          total_rule_price: this.total_pricing.total_rule_price,
          cut_price: this.total_pricing.cut_price,
          cut_price_display: this.total_pricing.cut_price_display,
          total_rule_price_display: this.total_pricing.total_rule_price_display,
          total_price_display: this.total_pricing.total_price_display,
          vat_display: this.total_pricing.vat_display,
          total_price_with_vat_display: this.total_pricing.total_price_with_vat_display,
          total_unused_price: this.total_pricing.total_unused_price,
          total_unused_price_display: this.total_pricing.total_unused_price_display,
        },
        virtual: this.virtual || null,
        estimate_list: this.estimate_list,
      }
      const host = window.location.href
      const params = {
        user_id: this.userId,
        type: 'P',
        url: host,
        data: null,
        estimate_json: JSON.stringify(estimate_json_obj),
      }
      try {
        await setEstimateRecord(params)
      } catch (err) {
        console.log(err)
      }
      const printWindow = window.open('견적서 인쇄', '인쇄', 'width=1024,height=800')
      const printArea = this.$refs.estimate.innerHTML
      let html = document.documentElement.innerHTML
      html = html.replace(/<body\b[^<]*(?:(?!<\/body>)<[^<]*)*<\/body>/ig, '<body>' + printArea + '</body>')
      html = html.replace(/<title\b[^<]*(?:(?!<\/title>)<[^<]*)*<\/title>/ig, '<title>견적서 인쇄</title>')

      printWindow.document.write('<html>' + html + '</html>')
      printWindow.document.close()
      window.setTimeout(() => {
        printWindow.focus()
        printWindow.print()
        printWindow.close()
      }, 1000)
    },
    sendEmail() {
      this.$emit('email')
    },
  },
};
</script>
