<template>
  <div class="estimate-modal-detail">
    <table class="estimate-modal-detail__table">
      <thead>
        <tr>
          <th>번호</th>
          <th>서비스/도메인</th>
          <th>상품</th>
          <th>단가</th>
          <th>수량(용량)</th>
          <th>기간</th>
          <th>금액</th>
          <th>비고</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(list, idx) in estimate_list" :key="idx">
          <td class="td__center">
            {{ idx + 1}}
          </td>
          <td class="td__service_domain">
            {{ list.carve_code_str }}<br>
            {{ list.domain }}
          </td>
          <td colspan="6" class="td__detail" ref="cell" :style="'height: ' + setSubTableHeight(idx)">
            <div class="detail-sub-wrap">
              <estimate-detail-sub :type="type" :detail="list.goods_lists" :index="idx" @height="getSubTableHeight"></estimate-detail-sub>
            </div>
          </td>
        </tr>
        <tr v-if="summary.total_unused_price != 0" class="summary-row">
          <td colspan="5" class="td__center" style="padding-left: 90px;">
            <span>
              미사용 금액 합계
            </span>
          </td>
          <td colspan="2" class="td__right rule-discount">-{{ summary.total_unused_price_display }}원</td>
          <td></td>
        </tr>
        <tr v-if="summary.cut_price != 0" class="summary-row">
          <td colspan="5" class="td__center" style="padding-left: 90px;">
            <span>
              100원 미만 절사 금액 합계
            </span>
          </td>
          <td colspan="2" class="td__right rule-discount">-{{ summary.cut_price_display }}원</td>
          <td></td>
        </tr>
        <tr class="summary-row">
          <td colspan="5" class="td__center" style="padding-left: 90px;">소계</td>
          <td colspan="2" class="td__right">{{ summary.total_price_display }}원</td>
          <td></td>
        </tr>
        <tr class="summary-row">
          <td colspan="5" class="td__center" style="padding-left: 90px;">부가세</td>
          <td colspan="2" class="td__right">{{ summary.vat_display }}원</td>
          <td></td>
        </tr>
        <tr class="summary-row tot">
          <td colspan="5" class="td__center tit" style="padding-left: 90px;">총계</td>
          <td colspan="2" class="td__right price">{{ summary.total_price_with_vat_display }}원</td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <ul class="estimate-modal-detail__warn">
      <template v-if="virtual">
        <li>입금 계좌: {{ virtual.pg_company }} | {{ virtual.account }} | {{ virtual.price_display }}원</li>
        <li>입금 기한: {{ virtual.due_date }} (입금 기한이 지나면 자동 취소됩니다.)</li>
      </template>
      <li>서비스별 단순 수식으로 계산 시, 금액과 견적서에 표기되는 금액은 상이할 수 있습니다. (단, 실제 결제 금액과 견적서의 금액은 동일)</li>
      <li v-if="this.domainCnt > 0">도메인은 검색 시점과 상태에 따라 등록, 연장, 복구 등 신청이 불가능하거나 비용이 달라질 수 있습니다.</li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import EstimateDetailSub from '@/components/EstimateDetailSub.vue';

export default {
  name: 'estimate-detail',
  components: {
    EstimateDetailSub,
  },
  data() {
    return {
      serviceList: [],
      domainCnt: 0,
      subTableHeight: [],
    }
  },
  computed: {
    ...mapState({
      estimate_list: (state) => state.estimate_list,
      summary: (state) => state.total_pricing,
      type: (state) => state.type,
      virtual: (state) => state.virtual,
    }),
  },
  created() {
    this.createEstimate()
  },
  methods: {
    createEstimate() {
      this.estimate_list.forEach((ele) => {
        if (ele.carve_code === 'domain') this.domainCnt += 1
      });
    },
    getSubTableHeight(value) {
      this.subTableHeight.push(value.subHeight)
    },
    setSubTableHeight(idx) {
      return (this.subTableHeight[idx] + 1) + 'px'
    },
  },
};
</script>
