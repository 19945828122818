import dummy from '@/js/dummy_goods_v3.json';

export default {
  methods: {
    changeNumberFormat(num) {
      return num.toLocaleString('ko-KR')
    },
    getEstimateData(obj) {
      this.$store.commit('setVisible', true)
      console.log('data console for debugging')
      console.log(obj)
      if (obj) {
        this.$store.commit('setEstimateList', obj)
      } else {
        this.$store.commit('setEstimateList', dummy)
      }
      this.$store.commit('setLoading', false)
    },
    getHostHiworks() {
      if (this.$store.state.type !== 'order') {
        const host = window.location.hostname
        const find = host.indexOf('hiworks')
        if (find === -1) {
          this.$store.commit('setHostHiworks', false)
        } else {
          this.$store.commit('setHostHiworks', true)
        }
      }
    },
  },
}
