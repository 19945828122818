<template>
  <transition name="dialog-fade">
    <div v-show="isVisible" key="email" class="estimate-modal-email-send">
      <div class="estimate-modal-email-send__header">
        <span>
          이메일 발송
        </span>
        <img class="close" :src="STATIC_SITE + '/mail/estimate/btn_cancel_layer.svg'" alt="취소" @click="closeEmail">
      </div>
      <div class="estimate-modal-email-send__body">
        <p class="estimate-modal-email-send__desc">
          견적서를 수신할 이메일 주소를 입력해주세요.<br>
          (1일 최대 20회 발송)
        </p>
        <div v-for="cnt in this.emailCnt" :key="cnt">
          <estimate-email-input :index="cnt" :total="emailCnt" @addEmail="addEmail" @removeEmail="removeEmail" v-model="emailArr[cnt-1]" @validate="validationCheck" :close="closeVal"></estimate-email-input>
        </div>
        <div class="lib-center lib-mt-20">
          <button class="email-send-btn" :disabled="activeSendingBtn" @click="sendingEmail">
            발송
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapState } from 'vuex';
import EstimateEmailInput from '@/components/Form/EstimateEmailInput.vue';
import { sendingEmailProxy } from '@/api/proxy'

export default {
  name: 'estimate-email-send',
  components: {
    EstimateEmailInput,
  },
  data() {
    return {
      STATIC_SITE: process.env.VUE_APP_STATIC,
      emailCnt: 1,
      emailArr: [],
      validateArr: [],
      activeSendingBtn: false,
      closeVal: false,
    }
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    userEmail: {
      type: String,
    },
    userId: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      estimate_list: (state) => state.estimate_list,
      receiver_info: (state) => state.receiver_info,
      total_pricing: (state) => state.total_pricing,
      virtual: (state) => state.virtual,
      is_hiworks: (state) => state.isHiworks,
    }),
  },
  watch: {
    isVisible(val) {
      if (val) {
        this.closeVal = false
        this.emailCnt = 1
        this.emailArr = []
        this.validateArr = []
        this.emailArr.push(this.userEmail)
        this.validateArr.push(true)
      }
    },
    validateArr: {
      handler() {
        const find = this.validateArr.findIndex((e) => e === false)
        if (find !== -1) {
          this.activeSendingBtn = true
        } else {
          this.activeSendingBtn = false
        }
      },
      deep: true,
    },
  },
  methods: {
    closeEmail() {
      this.emailCnt = 1
      this.emailArr = []
      this.closeVal = true
      this.$emit('close')
    },
    addEmail() {
      this.emailCnt += 1
      this.emailArr.push('')
      this.validateArr.push(false)
    },
    removeEmail() {
      this.emailCnt -= 1
      this.emailArr.splice(-1)
      this.validateArr.splice(-1)
    },
    validationCheck(value) {
      this.validateArr.splice(value.idx - 1, 1, value.val)
    },
    async sendingEmail() {
      const params = {
        user_id: this.userId,
        url: window.location.href,
        email_list: this.emailArr,
        estimate_data: {
          type: 'order',
          version: 'v1',
          virtual: this.virtual ? {
            account: this.virtual.account,
            due_date: this.virtual.due_date,
            pg_company: this.virtual.pg_company,
            price: this.virtual.price,
            price_display: this.virtual.price_display,
          } : null,
          receiver_info: {
            receiver: this.receiver_info.receiver,
            date: this.receiver_info.date,
            pay_condition: this.receiver_info.pay_condition,
            expire_date: this.receiver_info.expire_date,
            total_price: this.receiver_info.total_price,
            phone_number: this.receiver_info.phone_number,
          },
          estimate_list: this.estimate_list,
          total_pricing: {
            total_rule_price: this.total_pricing.total_rule_price,
            cut_price: this.total_pricing.cut_price,
            cut_price_display: this.total_pricing.cut_price_display,
            total_rule_price_display: this.total_pricing.total_rule_price_display,
            total_price_display: this.total_pricing.total_price_display,
            vat_display: this.total_pricing.vat_display,
            total_price_with_vat_display: this.total_pricing.total_price_with_vat_display,
            total_unused_price: this.total_pricing.total_unused_price,
            total_unused_price_display: this.total_pricing.total_unused_price_display,
          },
          is_hiworks: this.is_hiworks,
        },
      }
      const res = await sendingEmailProxy(params)
      if (res.status === 200) {
        alert('견적서 메일이 발송되었습니다.')
      } else {
        const error_reason = res.data.errors[0].message.indexOf('ESE_001')
        if (error_reason === -1) {
          alert('메일 발송이 실패되었습니다.\n다시 시도하시기 바랍니다.')
        } else {
          alert('가비아에서 발행하는 견적서의 1일 최대 발송 횟수는 20회입니다.')
        }
      }
    },
  },
};
</script>
