import Vue from 'vue';
import Vuex from 'vuex';
import App from './views/EstimateModal.vue';
import store from './store';
import common from './js/common';
import '@/assets/scss/style.scss';

Vue.config.productionTip = false;
Vue.mixin(common);
Vue.use(Vuex)
new Vue({
  store,
  render: (h) => h(App),
}).$mount('#estimate_app');
