import axios from 'axios';

const config = {
  baseURL: process.env.VUE_APP_GABIA_USER_API,
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  headers: { 'Content-Type': 'application/json' },
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    return Promise.reject(error);
  },
);

export async function getGabiaUserInfo() {
  return  (await _axios.get('/v1/users/me', { params: {'load_contact': 'Y'}})).data
}
