import axios from 'axios';

const host = window.location.hostname
const find = host.indexOf('hiworks')

const config = {
  baseURL: (find === -1) ? process.env.VUE_APP_GABIA_PROXY : process.env.VUE_APP_HIWORKS_PROXY,
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  headers: { 'Content-Type': 'application/json' },
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    return error.response
  },
);

export async function sendingEmailProxy(params) {
  return await _axios.post('/auth/orderapi', {
    'url': 'estimate/send/mail',
    'data': params
   })
}