<template>
  <div class="estimate-modal-header">
    <div class="lib-inline-flex-center">
      <img v-if="is_hiworks" class="logo" :src="STATIC_SITE + '/mail/estimate/logo_hiworks.png'" alt="로고">
      <img v-else class="logo gabia" :src="STATIC_SITE + '/mail/estimate/logo_gabia.png'" alt="로고">
      <h1>견적서</h1>
    </div>
    <div class="lib-inline-flex-center">
      <span v-if="type !== 'order'">
        <estimate-button v-show="is_login && isSpecificExcept" class="lib-mr-10" type="email" @click="sendEmail"></estimate-button>
        <estimate-button class="lib-mr-10" type="print" @click="print"></estimate-button>
      </span>
      <span class="estimate-modal-header__close" @click="closeModal">
         <img class="estimate-modal-header__close-icon" :src="STATIC_SITE + '/mail/estimate/btn_cancel.svg'" alt="닫기">
         <img class="estimate-modal-header__close-icon-hover" :src="STATIC_SITE + '/mail/estimate/btn_cancel_hover.svg'" alt="닫기">
      </span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import EstimateButton from '@/components/Form/EstimateButton.vue';

export default {
  name: 'estimate-header',
  components: {
    EstimateButton,
  },
  data() {
    return {
      STATIC_SITE: process.env.VUE_APP_STATIC,
      isSpecificExcept: true,
    }
  },
  computed: {
    ...mapState({
      is_hiworks: (state) => state.isHiworks,
      is_login: (state) => state.isLogin,
      type: (state) => state.type,
    }),
  },
  mounted() {
    const path = window.location.pathname
    if (path === '/nologin/extend' || path === '/nologin/billing') {
      this.isSpecificExcept = false
    }
  },
  methods: {
    print() {
      this.$emit('print')
    },
    sendEmail() {
      this.$emit('email')
    },
    closeModal() {
      this.$store.commit('setLoading', true)
    },
  },
};
</script>
