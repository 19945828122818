<template>
  <div class="estimate-modal-back" :class="{'reverse': reverse}"></div>
</template>
<script>
export default {
  name: 'estimate-back',
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
