<template>
  <button class="estimate-button" @click="onclick">
    <div v-if="type == 'email'" class="lib-flex-center">
      <img class="estimate-button__icon" :src="STATIC_SITE + '/mail/estimate/btn_mail.svg'" alt="이메일발송">
      <img class="estimate-button__icon-hover" :src="STATIC_SITE + '/mail/estimate/btn_mail_hover.svg'" alt="이메일발송">
      <span class="estimate-button__text">이메일 발송</span>
    </div>
    <div v-if="type == 'print'" class="lib-flex-center">
      <img class="estimate-button__icon" :src="STATIC_SITE + '/mail/estimate/btn_print.svg'" alt="인쇄">
      <img class="estimate-button__icon-hover" :src="STATIC_SITE + '/mail/estimate/btn_print_hover.svg'" alt="인쇄">
      <span class="estimate-button__text">인쇄</span>
    </div>
  </button>
</template>
<script>

export default {
  name: 'estimate-button',
  data() {
    return {
      STATIC_SITE: process.env.VUE_APP_STATIC,
    }
  },
  props: {
    type: {
      type: String,
      required: true,
      default: 'email',
    },
  },
  methods: {
    onclick(e) {
      this.$emit('click', e)
    },
  },
};
</script>
