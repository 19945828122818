<template>
  <table class="estimate-modal-detail__sub">
    <tbody>
      <template>
        <tr v-for="(item, idx) in detail" :key="idx">
          <td>
            <img v-if="item.type !== 'goods'" class="sub-symbol" :src="STATIC_SITE + '/mail/estimate/ico_sub_symbol.svg'" alt="하위">
            <span :class="{'sub-name': item.type !== 'goods'}">{{ item.goods_name }}</span>
          </td>
          <td class="td__right">
            {{ item.unit_price }}<span v-if="item.unit_price !== '-' && item.unit_price !== ''">원</span>
          </td>
          <td class="td__center">
            {{ item.quantity }}<span v-if="item.volume && item.volume_unit">({{ item.volume }}{{ item.volume_unit}})</span>
          </td>
          <td class="td__center">
            <div v-for="(li, idx) in item.goods_order_term_display" :key="idx">
              {{ li }}
            </div>
          </td>
          <td class="td__right">
            {{ item.origin_supply_price }}<span v-if="item.origin_supply_price !== ''">원</span>
          </td>
          <td class="td__rule">
            <div v-if="item.change_goods_unused_price != null">
              {{ item.change_goods_unused_price.current_goods_name }} -> {{ item.goods_name }} 변경
            </div>
            <div v-for="(list, index) in item.pricing_rule_list" :key="index">
              {{ list.display_rule_name }} {{ list.display_value }} <span v-if="list.supply_rule_price > 0">+</span><span v-if="list.supply_rule_price !== ''">{{ list.supply_rule_price_display }}원</span>
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
<script>

export default {
  name: 'estimate-detail-sub',
  components: {
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    detail: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      STATIC_SITE: process.env.VUE_APP_STATIC,
    }
  },
  mounted() {
    this.calcHeight()
  },
  methods: {
    calcHeight() {
      const height = document.getElementsByClassName('estimate-modal-detail__sub')[this.index].clientHeight
      this.$emit('height', { subHeight: height, index: this.index })
    },
  },
};
</script>
