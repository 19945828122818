<template>
  <div class="estimate-modal-info">
    <ul class="estimate-modal-info__block">
      <li class="line lib-flex-center">
        <span class="title">수신</span>
        <span class="content">
          <input v-if="type !== 'order'" class="receiver-input" v-model="receiver_info.receiver" type="text" maxlength="20">
          <span class="print-text" :class="{'lib-inline-block': type === 'order'}">{{ receiver_info.receiver }}</span>
        </span>
      </li>
      <li class="line">
        <span class="title">견적일</span>
        <span class="content">{{ receiver_info.date }}</span>
      </li>
      <li class="line">
        <span class="title">지불조건</span>
        <span class="content">{{ receiver_info.pay_condition }}</span>
      </li>
      <li class="line">
        <span class="title">유효기간</span>
        <span class="content">{{ receiver_info.expire_date }}</span>
      </li>
      <li class="line">
        <span class="title">
          총 결제 금액
          <div class="sub-desc">부가세 포함</div>
        </span>
        <span class="content total-price">
          {{ receiver_info.total_price }}원
        </span>
      </li>
    </ul>
    <ul class="estimate-modal-info__block">
      <li class="line">
        <span class="title">상호</span>
        <span class="content">(주) 가비아</span>
      </li>
      <li class="line">
        <span class="title">등록번호</span>
        <span class="content">214-86-39239</span>
      </li>
      <li class="line">
        <span class="title">주소</span>
        <span class="content">경기도 성남시 분당구 대왕판교로 660 (삼평동)<span class="block"></span>유스페이스1 B동 4층</span>
      </li>
      <li class="line">
        <span class="title">전화번호</span>
        <span class="content">{{ receiver_info.phone_number }}</span>
      </li>
      <li class="line">
        <span class="title">대표자</span>
        <span class="content">
          김홍국
          <img class="seal" :src="STATIC_SITE + '/mail/estimate/logo_gabia_seal.png'" alt="">
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'estimate-info',
  data() {
    return {
      STATIC_SITE: process.env.VUE_APP_STATIC,
    }
  },
  computed: {
    ...mapState({
      type: (state) => state.type,
      receiver_info: (state) => state.receiver_info,
    }),
  },
  mounted() {
  },
};
</script>
