<template>
  <div class="estimate-email-input" :class="{'lib-mt-20': index === 2}">
    <input class="email-id" type="text" v-model="emailId" @blur="validateEmail">
    <span class="at">@</span>
    <input v-if="emailAddressSelected == ''" class="email-address lib-mr-10" type="text" v-model="emailAddress" @blur="validateEmail">
    <div class="custom-select">
      <select class="address-select" name="" id="" v-model="emailAddressSelected" @change="validateEmail">
        <option v-for="(item) in addressOptions" :key="item.value" :value="item.value">
          {{ item.label }}
        </option>
      </select>
      <i class="icon fa fa-caret-down"></i>
    </div>
    <button v-if="total === 1" class="email-btn" @click="addEmail">
      메일 추가
    </button>
    <button v-if="index > 1" class="email-btn" @click="removeEmail">
      삭제
    </button>
    <p class="validation" v-if="validate != ''">
      <span v-if="validate === '1'">
        이메일 주소를 입력해주세요.
      </span>
      <span v-else-if="validate === '2'">
        정확한 이메일 주소를 입력해주세요.
      </span>
    </p>
  </div>
</template>
<script>

export default {
  name: 'estimate-email-input',
  data() {
    return {
      emailAddressSelected: '',
      emailId: '',
      emailAddress: '',
      validate: '',
      emailComplete: '',
      addressOptions: [
        { value: '', label: '직접 입력' },
        { value: 'naver.com', label: 'naver.com' },
        { value: 'gmail.com', label: 'gmail.com' },
        { value: 'hanmail.net', label: 'hanmail.net' },
        { value: 'hotmail.com', label: 'hotmail.com' },
        { value: 'nate.com', label: 'nate.com' },
        { value: 'korea.com', label: 'korea.com' },
        { value: 'kakao.com', label: 'kakao.com' },
      ],
    }
  },
  props: {
    index: {
      type: Number,
    },
    total: {
      type: Number,
    },
    value: {
      type: String,
    },
    close: {
      type: Boolean,
    },
  },
  watch: {
    close(val) {
      if (val) {
        this.validate = ''
      }
    },
    value(val) {
      if (val) {
        const [e_id, e_addr] = val.split('@')
        this.emailId = e_id
        const find = this.addressOptions.find((e) => e.value === e_addr)
        if (find) {
          this.emailAddressSelected = find.value
        } else {
          this.emailAddressSelected = ''
          this.emailAddress = e_addr
        }
      }
    },
    emailAddressSelected(val) {
      if (val === '') {
        this.emailAddress = ''
        this.validateEmail()
      }
    },
  },
  methods: {
    addEmail() {
      this.$emit('addEmail')
    },
    removeEmail() {
      this.$emit('removeEmail')
    },
    validateEmail() {
      const regId = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣\s]/
      const regAddress = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]*$/i

      if (this.emailAddressSelected === '') {
        if (this.emailId === '' || this.emailAddress === '') {
          this.validate = '1'
        } else {
          if (!regId.test(this.emailId) && regAddress.test(this.emailAddress)) {
            this.validate = ''
            this.emailComplete = this.emailId + '@' + this.emailAddress
          } else {
            this.validate = '2'
          }
        }
      } else if (this.emailAddressSelected !== '') {
        if (this.emailId === '') {
          this.validate = '1'
        } else {
          if (!regId.test(this.emailId)) {
            this.validate = ''
            this.emailComplete = this.emailId + '@' + this.emailAddressSelected
          } else {
            this.validate = '2'
          }
        }
      }
      if (this.validate === '') {
        this.$emit('input', this.emailComplete)
        this.$emit('validate', { idx: this.index, val: true })
      } else {
        this.$emit('validate', { idx: this.index, val: false })
      }
    },
  },
};
</script>
