<template>
  <div v-show="isVisible" class="estimate-modal">
    <estimate-back :reverse="layer_reverse"></estimate-back>
    <transition name="dialog-fade">
      <div v-show="isContentOn" key="main" class="estimate-modal-container">
        <estimate-wrapper @email="modalLayerReverse" :user-id="userId"></estimate-wrapper>
      </div>
    </transition>
    <estimate-email-send :is-visible="emailVisible" :user-id="this.userId" :user-email="userEmail" @close="closeEmail"></estimate-email-send>
  </div>
</template>

<script>
import EstimateBack from '@/components/EstimateBack.vue';
import EstimateWrapper from '@/components/EstimateWrapper.vue';
import EstimateEmailSend from '@/components/EstimateEmailSend.vue';
import common from '@/js/common';
import { mapState } from 'vuex';
import { getGabiaUserInfo } from '@/api/gabia-user';

export default {
  name: 'estimate-modal',
  mixins: [common],
  components: {
    EstimateBack,
    EstimateWrapper,
    EstimateEmailSend,
  },
  data() {
    return {
      layer_reverse: false,
      emailVisible: false,
      isContentOn: false,
      userEmail: '',
      userId: '',
    }
  },
  computed: {
    ...mapState({
      isVisible: (state) => state.isVisible,
      isLoading: (state) => state.isLoading,
    }),
  },
  watch: {
    isLoading(value) {
      if (!value) {
        this.isContentOn = !value
      } else {
        this.isContentOn = !value
        setTimeout(() => {
          this.$store.commit('setVisible', false)
        }, 300)
      }
    },
  },
  async created() {
    try {
      const res = await getGabiaUserInfo();
      this.userEmail = res.data.contact.email
      this.userId = res.data.user_id
      this.$store.commit('setLogin', true)
    } catch (e) {
      this.$store.commit('setLogin', false)
    }
    // const obj = null
    // this.getEstimateData(obj)
    this.getHostHiworks()
  },
  mounted() {
  },
  methods: {
    modalLayerReverse() {
      this.layer_reverse = true
      this.emailVisible = true
    },
    closeEmail() {
      this.emailVisible = false
      this.layer_reverse = false
    },
  },
};
</script>
<style lang="scss">
  .estimate-modal {
    h1, h2, h3, h4, th, td, input, select, textarea, button, a {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 400;
      font-family: 'Spoqa Han Sans','Malgun Gothic','맑은 고딕','Apple SD Gothic Neo','돋움',dotum, sans-serif;
    }
  }
</style>
